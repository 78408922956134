import Web3 from "web3";

const provider = window.ethereum;

const web3 = new Web3(provider);

export { web3 };

// import Web3 from "web3";
// import {setProviderAddress} from "./config";

// let web3 = '';

// const setProvider = (provider) => {
//    if(provider){
//       web3 = new Web3(provider);
//       setProviderAddress(web3);
//    }else{
//       web3 = "";
//    }
 
//    return web3;
// }

// export { web3, setProvider };