const LoaderComponent = (props) =>{
    return (
      <>
          <div className='loader'>
            <div className="loader-area">
              <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn96.png" alt="" />

              <span className="loading-text">
                <p className="mt-4">{props['message']}</p>
              </span>
            </div>
          </div>
      </>
    );
}

export default LoaderComponent;
