import { connect } from 'react-redux';
import React, { Component } from 'react';
import './index.css';
import LoaderComponent from './loader';

class Loader extends Component {

  render() {
    const { setLoader } = this.props
    return (
      <>
        {setLoader['status'] == true &&
          <LoaderComponent message={setLoader['message']}/>
        }
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ Auth }) => {
  let { setLoader } = Auth;
  return { setLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
